/* eslint-disable camelcase */
import 'reflect-metadata';
import { Amplify } from 'aws-amplify';
import { BrowserHistory } from 'history';
import i18next from 'i18next';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Store } from 'redux';
import { App } from './App';
import { Authentication } from './app/cross-cutting-concerns/authentication/Authentication';
import { AuthenticationService } from './app/cross-cutting-concerns/authentication/AuthenticationService';
import { DependencyInjection } from './app/cross-cutting-concerns/dependency-injection/DependencyInjection';
import { IDependencies } from './app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { FeatureFlagService } from './app/cross-cutting-concerns/feature-flags/FeatureFlagService';
import { FeatureFlagActions } from './app/cross-cutting-concerns/feature-flags/state/featureFlagSlice';
import { MenuItemManager } from './app/cross-cutting-concerns/routing/MenuItemManager';
import { BrowserStorage } from './app/cross-cutting-concerns/storage/BrowserStorage';
import { ToastService } from './app/cross-cutting-concerns/toasts/ToastService';
import { Translations } from './app/cross-cutting-concerns/translations/Translations';
import { CleaningPlanService } from './app/modules/cleaning/CleaningPlanService';
import { CleaningReportService } from './app/modules/cleaning/CleaningReportService';
import { FooService } from './app/modules/foo-management/FooService';
import { DateTime } from './lib/utils/date-handling/DateTime';
import reportWebVitals from './reportWebVitals';
import { Routing } from './app/cross-cutting-concerns/routing/Routing';
import { MachineService } from 'app/modules/machine-inventory/MachineService';
import { SiteService } from 'app/modules/site-management/SiteService';
import { UserService } from 'app/modules/user-management/UserService';
import { NotificationService } from 'app/modules/notification/NotificationService';
import { CleaningTaskReportService } from 'app/modules/cleaning/CleaningTaskReportService';
import { CleaningReportSubscriptionService } from 'app/modules/cleaning/CleaningReportSubscriptionService';
import { ReminderService } from 'app/modules/reminder/ReminderService';
import { TermsConditionsService } from 'app/modules/terms-conditions/TermsConditionsService';
import { Scripts } from 'app/utils/scripts/Scripts';
import { OperatorService } from 'app/modules/notification/OperatorService';
import { AuthenticationActions } from 'app/cross-cutting-concerns/authentication/state/authenticationActions';
import { Styling } from 'app/cross-cutting-concerns/styling/Styling';
import { RobotService } from 'app/modules/machine-inventory/RobotService';
import { NoteService } from 'app/modules/note/NoteService';
import { MachineAttachmentService } from 'app/modules/machine-inventory/MachineAttachmentService';

/**
 *  Initializes the application
 */
export async function init(): Promise<void> {
  Amplify.configure({
    Auth: {
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
      mandatorySignIn: true,
    },
    oauth: {
      domain: process.env.REACT_APP_AWS_USER_POOL_DOMAIN,
      scope: ['openid', 'profile', 'email', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${window.location.origin}/init`,
      redirectSignOut: `${window.location.origin}/logout`,
      responseType: 'code',
    },
    API: {
      aws_appsync_graphqlEndpoint: process.env.REACT_APP_AM_API_GRAPHQL_HTTP_ENDPOINT,
      aws_appsync_region: 'eu-west-1',
      aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    },
  });

  Scripts.addScriptTags();

  Translations.initialize();
  DateTime.initialize();

  DependencyInjection.registerDependencies();

  const styling = DependencyInjection.resolve<Styling>('Styling');
  const history = DependencyInjection.resolve<BrowserHistory>('History');
  const routing = DependencyInjection.resolve<Routing>(Routing);

  const dependencies: IDependencies = {
    fooService: DependencyInjection.resolve<FooService>(FooService),
    authenticationService: DependencyInjection.resolve<AuthenticationService>(AuthenticationService),
    cleaningTaskReportService: DependencyInjection.resolve<CleaningTaskReportService>(CleaningTaskReportService),
    cleaningPlanService: DependencyInjection.resolve<CleaningPlanService>(CleaningPlanService),
    cleaningReportService: DependencyInjection.resolve<CleaningReportService>(CleaningReportService),
    cleaningReportSubscriptionService: DependencyInjection.resolve<CleaningReportSubscriptionService>(
      CleaningReportSubscriptionService
    ),
    machineService: DependencyInjection.resolve<MachineService>(MachineService),
    machineAttachmentService: DependencyInjection.resolve<MachineAttachmentService>(MachineAttachmentService),
    siteService: DependencyInjection.resolve<SiteService>(SiteService),
    userService: DependencyInjection.resolve<UserService>(UserService),
    operatorService: DependencyInjection.resolve<OperatorService>(OperatorService),
    menuItemManager: DependencyInjection.resolve<MenuItemManager>(MenuItemManager),
    notificationService: DependencyInjection.resolve<NotificationService>(NotificationService),
    reminderService: DependencyInjection.resolve<ReminderService>(ReminderService),
    termsConditionsService: DependencyInjection.resolve<TermsConditionsService>(TermsConditionsService),
    toastService: DependencyInjection.resolve(ToastService),
    featureFlagService: DependencyInjection.resolve<FeatureFlagService>(FeatureFlagService),
    robotService: DependencyInjection.resolve<RobotService>(RobotService),
    noteService: DependencyInjection.resolve<NoteService>(NoteService),
    browserStorage: DependencyInjection.resolve<BrowserStorage>(BrowserStorage),
    t: i18next.t,
    history,
  };

  DependencyInjection.registerStore({
    dependencies,
  });

  const store = DependencyInjection.resolve<Store>('Store');

  // Creates a AuthenticationEventListener as a singleton to listen for authentication events from Amplify Hub
  // - See https://docs.amplify.aws/lib/utilities/hub/q/platform/js/
  DependencyInjection.resolve<Authentication>(Authentication);

  const featureFlagConfig = dependencies.featureFlagService.restoreFeatureFlagConfig();

  if (featureFlagConfig) {
    store.dispatch(FeatureFlagActions.setFeatureFlagConfig(featureFlagConfig));
  }

  const classificationAvailabilityConfig = dependencies.machineService.restoreClassificationAvailabilityConfig();

  if (classificationAvailabilityConfig) {
    store.dispatch(AuthenticationActions.setClassificationAvailabilityConfig(classificationAvailabilityConfig));
  }

  // TODO: Switch to React 18's createRoot once flicker issue is resolved by google-map-react
  // In the meantime we have to use the React 17 compatibility mode by still calling ReactDOM.render
  // * See https://github.com/google-map-react/google-map-react/issues/1117
  // * See https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
  ReactDOM.render(
    <React.StrictMode>
      <Suspense>
        <App store={store} styling={styling} history={history} routing={routing} dependencies={dependencies} />
      </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

init();
